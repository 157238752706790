<template>
  <!-- start agreement2 -->
  <section id="agreement2">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Согласие на обработку персональных данных</h1>

          <p>Физическое лицо, оставляя заявку на веб-сайте bookingeasy.ru через форму обратной связи, действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, предоставляет свое согласие на обработку персональных данных (далее – Согласие) Интернет-сервису, расположенному в сети Интернет по адресу bookingeasy.ru, на обработку своих персональных данных со следующими условиями:</p>

          <ol>
            <li>Согласие дается на обработку следующих моих персональных данных:
              <ul>
                <li>адрес электронной почты (e-mail); имя; номер мобильного телефона.</li>
              </ul>
            </li>
            <li>Цель обработки персональных данных: обсуждение возможного проекта</li>
            <li>В ходе обработки с персональными данными будут совершены следующие действия: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение; использование; передача (предоставление, доступ); блокирование; удаление; уничтожение.</li>
            <li>Третьи лица не обрабатывают персональные данные для указанной в согласии цели.</li>
            <li>Персональные данные обрабатываются до отказа в дальнейшем обсуждении проекта или до заключения договора на проект, смотря что произойдет быстрее.</li>
            <li>Согласие может быть отозвано вами или вашим представителем путем направления заявления на электронную почту info@bookingeasy.ru</li>
            <li>В случае отзыва вами или вашим представителем Согласия сервис bookingeasy.ru вправе продолжить обработку персональных данных без него при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.</li>
            <li>Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в п.5 и п.6 Согласия.</li>
          </ol>

        </div>
      </div>
    </div>
  </section>
  <!-- end agreement2 -->


</template>

<script>
export default {
  name: 'Agreement2View',
}
</script>