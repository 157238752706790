<template>
  <LoginView/>
  <!-- start help -->
  <section id="help" v-if="global.authenticated">
    <div class="overlay">
      <div class="container">
        <div class="d-flex align-items-start flex-column justify-content-center">
          <h1>Подключение</h1>
          <p>1. Нужно добавить группу объектов (сауну, баню и т.д. ) в ней добавить объекты (номера)</p>
          <p>2. Для того чтоб вам приходили уведомления в Телеграмм пройдите по ссылке <a href="https://t.me/bookingeasybot">https://t.me/bookingeasybot</a> нажмите "ЗАПУСТИТЬ" и введите код {{account.botKey}}</p>
          <p>3. Для просмотра своего виджета пройдите по ссылке <a :href="'https://bookingeasy.ru/api?group=0&key=' + account.key">https://bookingeasy.ru/api?group=0&key={{account.key}}</a> <br>
            Её можно вставлять в соцсети<br>
          </p>

          <p>4. Для подключения на своем сайте нужно поместить такой код в то место, где хотим чтобы появился виджет<br>
            &lt;booking-widget&gt;&lt;/booking-widget&gt;<br>
          </p>
          <p>В любом месте на странице нужно вставить скрипт<br>
            &lt;script src=&#34;https://bookingeasy.ru/api/widget?group=0&key={{account.key}}&#34;&gt;&lt;/script&gt;<br>
          </p>
          <p>Параметр key<br>Ваш ключ(менять не нужно)
          </p>
          <p>Параметр group<br>Номер группы обектов(например если подключено несколько саун, у первой будет номер 0, у второй будет 1 и т.д.)
          </p>
          <p>Для подключения в виде ссылки добавляем ссылку<br> &lt;a href=&#34;https://bookingeasy.ru/api?group=0&key={{account.key}}&#34;&gt;Забронировать&lt;/a&gt; </p>

          <br>
          <h1>Изменение внешнего вида</h1>
          <p>
            Для виджета предусмотренны следующие css свойства, для настройки внешнего вида. Если вам требуется что-то еще обратитесь в поддержку.
            <br>booking-widget {
            <br> --booking-widget-font-family: 'Roboto';
            <br> --booking-widget-text-color: green;
            <br> --booking-widget-background-color: #CCFFFF;
            <br> --booking-widget-main-btn-color: #165490;
            <br> --booking-widget-main-btn-text-color: #fff;
            <br> --booking-widget-agree-a-color: #165490;
            <br> --booking-widget-card-background-color: #FFFF99;
            <br> --booking-widget-booked-cell-color: black;
            <br> --booking-widget-booked-cell-text-color: pink;
            <br> --booking-widget-processing-cell-color: grey;
            <br> --booking-widget-processing-cell-text-color: pink;
            <br> --booking-widget-free-cell-color: lime;
            <br> --booking-widget-free-cell-text-color: pink;
            <br> --booking-widget-use-cell-color: red;
            <br> --booking-widget-use-cell-text-color: pink;
            <br> --booking-widget-free-cell-hover-color: blue;
            <br> --booking-widget-free-cell-hover-text-color: pink;
            <br>}
          </p>



        </div>

      </div>
    </div>
  </section>
  <!-- end help -->

</template>

<style>
#help .overlay{
  background: none;
}
</style>

<script>
import axios from 'axios'
import LoginView from '@/views/LoginView.vue'
import {global} from "@/global.js";

export default {
  name: 'LkHelpView',
  components: {
    LoginView
  },

  mounted() {
    global.value.lk=true;
    if(global.value.authenticated) {
      this.load();
    }else {
      setTimeout(function () {
        if (global.value.authenticated) {
          this.load();
        }
      }.bind(this), 1000);
    }
  },

  data() {
    return {
      global: global,
      account:{subjects:[]}
    }
  },

  methods: {
    load(){
      this.getAccount();
    },
    getAccount(){
      axios.get(global.value.url + "lk/account").then(response => {
        this.account = response.data;
      });
    }
  }
}
</script>
