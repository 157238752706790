<template>
  <!-- start agreement3 -->
  <section id="agreement3">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <h1>Соглашение об обработке персональных данных</h1>

          <p>Настоящее Пользовательское соглашение (именуемое в дальнейшем «Пользовательское Соглашение») регулирует отношения между Интернет-сервисом, расположенным в сети Интернет по адресу bookingeasy.ru (далее - Интернет-сервис), и физическим или юридическим лицом (именуемым в дальнейшем «Пользователь») в области предоставления и обработки персональных данных Пользователя.</p>
          <p><strong>Предмет Пользовательского Соглашения</strong></p>
          <ol>
            <li>Интернет-сервис производит обработку персональных данных пользователя на условиях, являющихся предметом настоящего Пользовательского Соглашения.</li>
          </ol>
          <p><strong>Общие положения</strong></p>
          <ol>
            <li>Пользовательское Соглашение вступает в силу и принимается Пользователем с момента подтверждения Пользователем заказа на сайте Интернет-сервиса, самостоятельной регистрации аккаунта, или на сайте партнера где установлен модуль или после подтверждения заказа по телефону, если Пользователь оформляет заказ по телефону, либо после создания заказа пользователем через модуль онлайн бронирования.</li>
            <li>Интернет-сервис сохраняет за собой право изменять, добавлять или удалять пункты настоящего Пользовательского Соглашения в любое время без какого-либо специального уведомления. Если Пользователь продолжает пользоваться услугами Интернет-сервиса после публикации изменений в Пользовательском Соглашении, считается, что Пользователь тем самым принимает изменения условий Пользовательского соглашения.</li>
            <li>Пользователем может быть любое дееспособное физическое лицо, способное принять и оплатить заказанный им товар в порядке и на условиях, установленных настоящим Пользовательским Соглашением, на территории Российской Федерации.</li>
            <li>Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.</li>
            <li>Пользователь несет ответственность за предоставление персональных данных иного лица.</li>
          </ol>
          <p><strong>Цель сбора и обработки персональных данных Пользователя</strong></p>
          <p>Личные данные, вводимые Пользователем при оформлении заказа, используются для совершения торговой сделки, выполнения услуги или улучшения качества обслуживания.</p>
          <p><strong>Состав персональных данных Пользователя</strong></p>
          <p>Пользователь дает согласие на обработку следующих персональных данных:</p>
          <ol>
            <li>Фамилия, имя и отчество</li>
            <li>Телефонный номер</li>
            <li>Email (почтовый адрес) адрес</li>
            <li>Другие данные введенные пользователем при оформлении заказа</li>
          </ol>
          <p>Интернет-сервис вправе посылать своим пользователям информационные сообщения. Используя сервисы модуля, Пользователь также в соответствии с ч. 1 ст. 18 Федерального закона «О рекламе» дает свое согласие на получение сообщений рекламного характера. Пользователь вправе отказаться от получения сообщений рекламного характера путем использования соответствующего функционала того сервиса, в рамках которого или в связи, с которым пользователем были получены сообщения рекламного характера.</p>
          <p>В целях повышения качества сервиса бронирования вправе осуществлять сбор мнений и отзывов Пользователей по различным вопросам путем направления информационного сообщения при очередном посещении Пользователем сервиса. Собранные мнения и отзывы могут быть использованы для формирования статистических данных, которые могут быть использованы в сервисе.</p>
          <p><strong>Срок хранения и обработки персональных данных Пользователей</strong></p>
          <p>Для обеспечения достаточного срока сервисного обслуживания продаваемых товаров, Интернет магазин устанавливает срок хранения и обработки персональных данных в 1 год с даты оформления заказа.</p>
          <p><strong>Порядок обработки персональных данных Пользователей</strong></p>
          <ol>
            <li>Пользователь соглашается с тем, что Интернет-сервис имеет право на хранение и обработку, в том числе и автоматизированную, любой информации, относящейся к персональным данным Пользователя в соответствии с Федеральным законом от 27.07.2006 Э 152-ФЗ «О персональных данных», включая сбор, систематизацию, накопление, хранение, уточнение, использование, распространение (в том числе передачу), обезличивание, блокирование, уничтожение персональных данных, предоставленных Пользователем.</li>
            <li>Обработка персональных данных Пользователя производится путем смешанной обработки персональных данных без передачи и с передачей по внутренней сети Интернет-сервиса, c передачей и без передачи по сети Интернет.</li>
            <li>В случае отзыва Пользователем согласия на обработку персональных данных, Интернет-сервис удаляет персональные данные Пользователя и не использует их в дальнейшем.</li>
            <li>В случае недееспособности субъекта персональных данных согласие на обработку его персональных данных дает в письменной форме законный представитель субъекта персональных данных.</li>
            <li>Пользователь дает своё согласие на обработку его персональных данных, а именно, совершение действий, предусмотренных п. 3 ст. 3 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», и подтверждает, что, давая такое согласие, он действует свободно, своей волей и в своем интересе.</li>
            <li>Пользователь даёт своё согласие на возможное осуществление трансграничной передачи персональных данных в соответствии со ст. 12 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
            <li>Исполнитель не несёт ответственности за использование (как правомерное, так и неправомерное) третьими лицами информации, размещенной Пользователем на Сайте.</li>
            <li>В случае смерти субъекта персональных данных согласие на обработку его персональных данных дают в письменной форме наследники субъекта персональных данных, если такое согласие не было дано субъектом персональных данных при его жизни.</li>
            <li>Лицо, ответственное за организацию обработки персональных данных пользователя – Солуянов Е.Е.</li>
          </ol>
          <p><strong>Передача персональных данных третьим лицам</strong></p>
          <ol>
            <li>Интернет-сервис обязуется не передавать личные данные Пользователей третьим лицам иначе как для целей по подтверждению заказа и его доставки пользователю.</li>
            <li>Интернет-сервис составляет за собой право в предусмотренных законом случаях передавать личные данные соответствующим службам, если это делается для защиты здоровья, жизни или свободы другого лица.</li>
          </ol>
          <p><strong>Безопасность</strong></p>
          <p>Интернет-сервис обеспечивает безопасность учетной записи Пользователя от несанкционированного доступа.</p>
          <p><strong>Уведомления об изменениях</strong></p>
          <p>Сайт оставляет за собой право вносить изменения в Политику конфиденциальности без дополнительных уведомлений. Нововведения вступают в силу с момента их опубликования. Пользователи могут отслеживать изменения в Политике конфиденциальности самостоятельно.</p>




        </div>
      </div>
    </div>
  </section>
  <!-- end agreement3 -->



</template>

<script>
export default {
  name: 'Agreement3View',
}
</script>