<template>
  <LoginView/>
  <!-- start orders -->
  <section id="orders" v-if="global.authenticated">
    <div class="overlay">
      <div class="container">
        <div class="d-flex align-items-start flex-column justify-content-center">

          <h4>Режим администратора для добавления заказов по телефону</h4>
          <br>

          <booking-widget-admin></booking-widget-admin>
          <br>
            <h4>Поиск заказов</h4>
          <div class="order-filter">
            <div class="order-filter-cell">
              Статус:&nbsp;
              <select class="order-filter-input" v-model="ordersFilter.state">
                <option value="-1">Все</option>
                <option value="0">Новые</option>
                <option value="1">Подтвержденные</option>
                <option value="2">Отмененные</option>
              </select>
            </div>
            <div class="order-filter-cell">
              Дата:&nbsp;
              <input class="order-filter-input" type="date" v-model="ordersFilter.date">
            </div>
            <div class="order-filter-cell">
              Клиент/телефон:&nbsp;
              <input class="order-filter-input" type="text" v-model="ordersFilter.text">
            </div>
            <div class="order-filter-cell">
              <input @click="loadOrders()" type="button" class="btn btn-sm btn-success main-btn text-uppercase"
                     value="Показать">
            </div>
          </div>
          <br>

          <table class="order-table">
            <tr>
              <td class="header-cell">Клиент</td>
              <td class="header-cell">Номер</td>
              <td class="header-cell">Дата</td>
              <td class="header-cell">Статус</td>
              <td class="header-cell">Действия</td>
            </tr>
            <tr v-if="orders.length==0">
              <td class="cell" colspan=6 style="text-align: center;">Нет данных</td>
            </tr>
            <tr v-for="(order) in orders" :key="order.id">
              <td class="cell">{{ order.customer }} ({{ order.phone }})</td>
              <td class="cell">{{ order.subjectName }}</td>
              <td class="cell">{{ order.dateText }}</td>
              <td class="cell">{{ order.state == 0 ? "Новый" : order.state == 1 ? "Подтвержден" : "Отменен" }}</td>
              <td class="cell">
                <input v-if="order.state!=1" @click="setState(order.id,1)" type="button"
                       class="btn btn-sm btn-success text-uppercase" value="Подтвердить">
                <input v-if="order.state!=2" @click="setState(order.id,2)" type="button"
                       class="btn btn-sm btn-danger text-uppercase" value="Отменить">
              </td>
            </tr>
          </table>

          <div class="order-cards">
            <div v-if="orders.length==0" class="order-card">
              <div class="order-card-cell">Нет данных</div>
            </div>
            <div v-for="(order) in orders" :key="order.id" class="order-card">
              <div class="order-card-cell">Клиент: {{ order.customer }} ({{ order.phone }})</div>
              <div class="order-card-cell">Номер: {{ order.subjectName }}</div>
              <div class="order-card-cell">Дата: {{ order.dateText }}</div>
              <div class="order-card-cell">Статус:
                {{ order.state == 0 ? "Новый" : order.state == 1 ? "Подтвержден" : "Отменен" }}
              </div>
              <div class="order-card-cell">
                <input v-if="order.state!=1" @click="setState(order.id,1)" type="button"
                       class="btn btn-sm btn-success text-uppercase" value="Подтвердить">
                <input v-if="order.state!=2" @click="setState(order.id,2)" type="button"
                       class="btn btn-sm btn-danger text-uppercase" value="Отменить">
              </div>
            </div>
          </div>

          <br>
          <div class="order-filter" v-if="showAddOrdersBtn">
            <div class="order-filter-cell">
              <input @click="addOrders()" type="button" class="btn btn-sm btn-success main-btn text-uppercase"
                     value="Показать ещё">
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!-- end orders -->

</template>

<style>
#orders .overlay {
  background: none;
}

#orders {
  /*background: url('../images/b1.jpg') no-repeat;*/
  background-size: cover;
  text-align: center;
  /*height: calc(100% - 145px);*/
}

#orders .order-filter {
  background: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

#orders .order-filter-cell {
  padding: 1px 16px;
  box-sizing: border-box;
  font-weight: 500;
  text-align: left;
  height: 56px;
  display: flex;
  align-items: center;
}

#orders .header-cell {
  height: 56px;
  padding: 1px 16px;
  box-sizing: border-box;
  font-weight: 500;
  text-align: left;
}

#orders .cell {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
  height: 52px;
  padding: 1px 16px;
  text-align: left;
}

#orders .order-table {
  background: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  width: 100%;
}

#orders .order-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

#orders .order-cards {
  display: none;
}

#orders .order-card {
  background: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  margin-bottom: 16px;
  padding: 16px;

}

#orders .btn-danger, #orders .btn-success {
  border-radius: 100px;
}

@media (max-width: 768px) {
  #orders .order-cards {
    display: inline;
  }

  #orders .order-table {
    display: none;
  }
}

.order-filter-input {
  height: 26px;
}
</style>

<script>
import axios from 'axios'
//import Toast from '@/js/toast.min.js'
import LoginView from '@/views/LoginView.vue'
import {global} from "@/global.js";

export default {
  name: 'LkOrderView',
  components: {
    LoginView
  },
  data() {
    return {
      global: global,
      errored: false,
      loading: false,
      ordersFilter: {state: 0, text: "", date: null},
      orders: [],
      account: {subjects: []},
    }
  },

  mounted() {
    global.value.lk = true;
    if (global.value.authenticated) {
      this.load();
    } else {
      setTimeout(function () {
        if (global.value.authenticated) {
          this.load();
        }
      }.bind(this), 1000);
    }
  },


  methods: {
    load() {
      this.getAccount();
      this.loadOrders();
    },
    getAccount() {
      axios.get(global.value.url + "lk/account").then(response => {
        this.account = response.data;
        if (this.account.groups.length == 0) {
          this.$router.push("/setup");
        } else {
          if (document.getElementById("admin-booking-widget") != null) {
            document.getElementById("admin-booking-widget").remove();
          }
          if (document.getElementById("admin-booking-widget") == null) {
            var script = document.createElement('script');
            script.id = "admin-booking-widget";
            script.src = 'https://bookingeasy.ru/api/widget?type=1&mode=3&admin=true&key=' + this.account.key;
            document.head.appendChild(script);
          }
        }
      });
    },
    loadOrders() {
      this.loading = true;
      this.ordersFilter.page = 0;
      this.ordersFilter.size = 10;
      axios
          .get(global.value.url + "lk/order", {params: this.ordersFilter})
          .then(response => {
            this.orders = response.data;
            if (response.data.length == 10) {
              this.showAddOrdersBtn = true;
            } else {
              this.showAddOrdersBtn = false;
            }
            this.errored = false;
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    addOrders() {
      this.loading = true;
      this.ordersFilter.page = parseInt(this.orders.length / 10);
      this.ordersFilter.size = 10;
      var orders = this.orders;
      axios
          .get(global.value.url + "lk/order", {params: this.ordersFilter})
          .then(response => {
            response.data.forEach(function (item) {
              orders.push(item);
            });
            if (response.data.length == 10) {
              this.showAddOrdersBtn = true;
            } else {
              this.showAddOrdersBtn = false;
            }
            this.errored = false;
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    setState(order, state) {
      this.loading = true;
      axios
          .put(global.value.url + "lk/order", {"id": order, "state": state})
          .then(() => {
            this.loadOrders();
            this.errored = false;
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  }
}
</script>
