import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LkOrderView from '../views/LkOrderView.vue'
import LkSetupView from '../views/LkSetupView.vue'
import LkHelpView from '../views/LkHelpView.vue'
import LkBalanceView from '../views/LkBalanceView.vue'
import LoginView from '../views/LoginView.vue'
import Agreement1View from '../views/Agreement1View.vue'
import Agreement2View from '../views/Agreement2View.vue'
import Agreement3View from '../views/Agreement3View.vue'
import DocView from '../views/doc/DocView.vue'




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/orders',
    name: 'orders',
    component: LkOrderView
  },
  {
    path: '/setup',
    name: 'setup',
    component: LkSetupView
  },
  {
    path: '/help',
    name: 'help',
    component: LkHelpView
  },
  {
    path: '/balance',
    name: 'balance',
    component: LkBalanceView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/login/:page',
    name: 'login',
    component: LoginView,
    props: true
  },
  {
    path: '/agreement1',
    name: 'agreement1',
    component: Agreement1View
  },
  {
    path: '/agreement2',
    name: 'agreement2',
    component: Agreement2View
  },
  {
    path: '/agreement3',
    name: 'agreement3',
    component: Agreement3View
  },
  {
    path: '/doc',
    name: 'doc',
    component: DocView
  },
  {
    path: '/doc/:page',
    name: 'doc',
    component: DocView,
    props: true
  }
  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" *//* '../views/AboutView.vue')
  }*/
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  mode: 'hash',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return ({
        el: to.hash,
        behavior: 'smooth',
      })
    } else if (savedPosition) {
      return (savedPosition);
    } else {
      return {left: 0, top: 0}
    }
  }
})

/*router.history.scrollBehavior = function (to) {
  if (to.hash) {
    return { selector: to.hash }
  } else {
    return { left: 0, top: 0 }
  }
}*/

export default router
