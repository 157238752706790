<template>
    <div>
      <h2>Инструкция по приему платежей через Тинькофф</h2>
      <p>Оставить заявку на подключение <a href="https://www.tinkoff.ru/kassa/">сайт Тинькофф</a> </p>
      <p>Дождаться проверки магазина</p>
      <p>Получить настройки в Личном кабинете</p>
      <p>Ввести выданные настройки в личном кабинете в разделе "Настройки".</p>
      <p>Ввести в настройках терминала Тинькофф в поле уведомлений значение "https://bookingeasy.ru/api/pay-notification"</p>
      <p>Выполнить тестовые платежи (документация)</p>
      <p>Подключить онлайн кассу и ОФД к платежной системе.</p>
      <p>Если потребуется помощь в интеграции, можем помочь все наладить, обращайтесь в техподдержку.</p>
      <img src="https://bookingeasy.ru/images/doc/tinkoff0.jpg" class="img-doc"><br><br>
    </div>
</template>

<style>

</style>

<script>
export default {
  name: 'DocTinkoffView'
}
</script>
