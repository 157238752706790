<template>
    <div>
      <h2>Изменение внешнего вида</h2>
                <p>
                  Для виджета предусмотренны следующие css свойства, для настройки внешнего вида. Если вам требуется что-то еще обратитесь в поддержку.
                  <br>booking-widget {
                  <br> --booking-widget-font-family: 'Roboto';
                  <br> --booking-widget-text-color: green;
                  <br> --booking-widget-background-color: #CCFFFF;
                  <br> --booking-widget-main-btn-color: #165490;
                  <br> --booking-widget-main-btn-text-color: #fff;
                  <br> --booking-widget-agree-a-color: #165490;
                  <br> --booking-widget-card-background-color: #FFFF99;
                  <br> --booking-widget-booked-cell-color: black;
                  <br> --booking-widget-booked-cell-text-color: pink;
                  <br> --booking-widget-processing-cell-color: grey;
                  <br> --booking-widget-processing-cell-text-color: pink;
                  <br> --booking-widget-free-cell-color: lime;
                  <br> --booking-widget-free-cell-text-color: pink;
                  <br> --booking-widget-use-cell-color: red;
                  <br> --booking-widget-use-cell-text-color: pink;
                  <br> --booking-widget-free-cell-hover-color: blue;
                  <br> --booking-widget-free-cell-hover-text-color: pink;
                  <br>}
                </p>
    </div>
</template>

<style>

</style>

<script>
export default {
  name: 'DocTinkoffView'
}
</script>
