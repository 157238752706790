<template>
  <LoginView/>
  <!-- start lk -->
  <section id="lk" v-if="global.authenticated">
    <div class="overlay">
      <div class="container">
        <div class="d-flex align-items-start flex-column justify-content-center">
          <div class="ddd-tab">
            <div class="ddd-tab-head">
              <div class="ddd-tab-tab" @click="tab=0" :class="{'ddd-tab-active': tab==0}">
                Настройки
              </div>
              <div class="ddd-tab-tab" @click="tab=1" :class="{'ddd-tab-active': tab==1}">
                Группы
              </div>
              <div class="ddd-tab-tab" @click="tab=2" :class="{'ddd-tab-active': tab==2}">
                Пароль
              </div>
            </div>
            <div class="ddd-tab-content" v-if="tab==0">
              <table class="main-setings">
                <tr>
                  <td className="cell">Как к вам обращаться:</td>
                  <td className="cell"><input type="text" v-model="account.fio"/></td>
                </tr>
                <tr>
                  <td className="cell">Телефон:</td>
                  <td className="cell"><input type="text" v-model="account.phone"/></td>
                </tr>
                <tr>
                  <td className="cell">Максимальное кол-во дней от сегодня, когда бронирование возможно:</td>
                  <td className="cell"><input type="number" v-model="account.maxDays"/></td>
                </tr>
                <tr>
                  <td className="cell">Напоминать в Телеграм о не принятых решениях по бронированию:</td>
                  <td className="cell"><input type="checkbox" v-model="account.sendReminders"/></td>
                </tr>
                <!--tr>
                  <td class="cell">Тип виджета:</td>
                  <td class="cell"><input type="number" v-model="account.type"></td>
                </tr-->
                <tr>
                  <td className="cell">Режим работы:</td>
                  <td className="cell"><select v-model="account.mode">
                    <option value="0">Просмотр</option>
                    <option disabled style="font-style:italic">&nbsp;&nbsp;Только просмотр свободных номеров</option>
                    <option value="1">Заказ без оплаты</option>
                    <option disabled style="font-style:italic">&nbsp;&nbsp;Подтверждение брони администратором и оплата
                      вне системы
                    </option>
                    <option value="2">Заказ и оплата</option>
                    <option disabled style="font-style:italic">&nbsp;&nbsp;Автоматическое подтверждение брони и оплата
                      на сайте
                    </option>
                  </select></td>
                </tr>
                <tr>
                  <td className="cell">Текст вверху виджета:</td>
                  <td className="cell"><input type="text" v-model="account.mainText"/></td>
                </tr>


                <tr v-if="account.mode==2">
                  <td className="cell">Интернет-Эквайринг:</td>
                  <td className="cell"><select v-model="account.acquiring" required>
                    <option value="tinkoff">Тинькоф</option>
                    <option value="yookassa">Юкасса</option>
                    <option disabled style="font-style:italic">Добавим ваш, обратитесь в поддержку</option>
                  </select></td>
                </tr>
                <tr v-if="account.mode==2 && account.acquiring!=null ">
                  <td className="cell">Сумма для бронирования:</td>
                  <td className="cell"><input type="number" v-model="account.bookingAmount" required/></td>
                </tr>
                <tr v-if="account.mode==2 && account.acquiring!=null">
                  <td className="cell">Ссылка на ваш сайт:</td>
                  <td className="cell"><input type="text" v-model="account.siteUrl" required/></td>
                </tr>
                <tr v-if="account.mode==2 && account.acquiring=='tinkoff'">
                  <td className="cell">Терминал:</td>
                  <td className="cell"><input type="text" v-model="account.terminalKey" required/></td>
                </tr>
                <tr v-if="account.mode==2 && account.acquiring=='tinkoff'">
                  <td className="cell">Пароль терминала:</td>
                  <td className="cell"><input type="text" v-model="account.terminalPassword" required/></td>
                </tr>
                <tr v-if="account.mode==2 && account.acquiring=='tinkoff'">
                  <td className="cell">Система налогообложения:</td>
                  <td className="cell"><select v-model="account.taxation" required>
                    <option value="osn">Общая</option>
                    <option value="usn_income">Упрощенная (доходы)</option>
                    <option value="usn_income_outcome">Упрощенная (доходы минус расходы)</option>
                    <option value="patent">Патентная</option>
                    <option value="envd">Единый налог на вмененный доход</option>
                    <option value="esn">Единый сельскохозяйственный налог</option>
                  </select></td>
                </tr>
                <tr v-if="account.mode==2 && account.acquiring=='tinkoff'">
                  <td className="cell">Ставка НДС:</td>
                  <td className="cell"><select v-model="account.tax" required>
                    <option value="none">без НДС</option>
                    <option value="vat0">0%</option>
                    <option value="vat10">10%</option>
                    <option value="vat20">20%</option>
                    <option value="vat110">10/110</option>
                    <option value="vat120">20/120</option>
                  </select></td>
                </tr>
                <tr v-if="account.mode==2 && account.acquiring=='yookassa'">
                  <td className="cell">Идентификатор магазина:</td>
                  <td className="cell"><input type="number" v-model="account.shopId" required/></td>
                </tr>
                <tr v-if="account.mode==2 && account.acquiring=='yookassa'">
                  <td className="cell">Секретный ключ:</td>
                  <td className="cell"><input type="text" v-model="account.shopKey" required/></td>
                </tr>
                <tr>
                  <td className="cell"><p class="text-danger">{{accountErorr1}}</p></td>
                  <td className="cell"><input @click="setAccount()" type="button"
                    class="btn btn-sm btn-success main-btn text-uppercase" value="Сохранить">
                  </td>
                </tr>
              </table>

            </div>


      <div class="ddd-tab-content" v-if="tab==1">
        <table class="main-setings">
          <tr>
            <td class="cell">
              <div class="title">Группы объектов (например сауна, квест-студия и т.д.) (Тариф считается за каждую
                      подключенную)
                    </div>
                    <br>
                    <div class="ddd-tab">
                      <div class="ddd-tab-head">
                        <div v-for="(group,index1) in account.groups" :key="index1" class="ddd-tab-tab"
                             @click="tab1=group.num; tab2=0;" :class="{'ddd-tab-active': tab1==group.num}">
                          {{ group.name }}
                        </div>
                        <div class="ddd-tab-tab" @click="addGroup()">
                          +(Добавить)
                        </div>
                      </div>
                      <div v-for="(group,index1) in account.groups" :key="index1" class="ddd-tab-content">
                        <div v-if="group.delete==false && group.num==tab1">
                          <table>
                            <tr>
                              <td class="cell">Номер групы(Передается как параметр в виджет):</td>
                              <td class="cell"><input type="text" v-model="group.num" readonly></td>
                            </tr>
                            <tr>
                              <td class="cell">Название:</td>
                              <td class="cell"><input type="text" v-model="group.name"></td>
                            </tr>
                            <tr>
                              <td class="cell">Подключен:</td>
                              <td class="cell"><input type="checkbox" v-model="group.enabled"></td>
                            </tr>
                            <tr>
                              <td class="cell" colspan="2">

                                <div class="title">Объекты бронирования (например номера сауны, квесты, т.д.)</div>
                                <br>
                                <div class="ddd-tab-head">
                                  <div v-for="(subject,index2) in group.subjects" :key="index2" class="ddd-tab-tab"
                                       @click="tab2=index2" :class="{'ddd-tab-active': tab2==index2}">
                                    {{ subject.name }}
                                  </div>
                                  <div class="ddd-tab-tab" @click="addSubject(group)">
                                    +(Добавить)
                                  </div>
                                </div>
                                <div v-for="(subject,index2) in group.subjects" :key="index2" class="ddd-tab-content">
                                  <div v-if="subject.delete==false && index2==tab2">
                                    <table>
                                      <!--tr>
                                        <td class="cell">Группа(номер объекта, когда несколько саун)</td>
                                        <td class="cell"><input type="number" v-model="subject.group"></td>
                                      </tr-->
                                      <tr>
                                        <td class="cell">Порядок отображения:</td>
                                        <td class="cell"><input type="number" v-model="subject.ord"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Название:</td>
                                        <td class="cell"><input type="text" v-model="subject.name"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Описание:</td>
                                        <td class="cell"><input type="text" v-model="subject.description"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Подключен:</td>
                                        <td class="cell"><input type="checkbox" v-model="subject.enabled"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Ссылка:</td>
                                        <td class="cell"><input type="url" v-model="subject.uri"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Главная картинка:</td>
                                        <td class="cell">
                                          <label :for="'myfile' + index1 + index2" class="file-label"><img alt=""
                                                                                                           :src="subject.imageUri"
                                                                                                           style="height: 70px;"></label>
                                          <input class="file" :id="'myfile' + index1 + index2" type="file"
                                                 @change="sync($event, subject)" accept=".jpg, .jpeg">
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Минимальное время бронирования(мин, кратно 60):</td>
                                        <td class="cell"><input type="number" v-model="subject.minTime"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Максимальное время бронирования(мин, кратно 60):</td>
                                        <td class="cell"><input type="number" v-model="subject.maxTime"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Час с которого начинаем работать(0 по умолчанию):</td>
                                        <td class="cell"><input type="number" v-model="subject.startHour"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Час до которого работаем(24 по умолчанию):</td>
                                        <td class="cell"><input type="number" v-model="subject.endHour"></td>
                                      </tr>
                                      <tr>
                                        <td class="cell">Время на уборку, добавится как занятое к времени брони (мин,
                                          кратно 60):
                                        </td>
                                        <td class="cell"><input type="number" v-model="subject.cleaningTime"></td>
                                      </tr>
                                      <tr>
                                        <td colspan="2" style="text-align:left">
                                          Дополнительные картинки:
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2" style="text-align:left">
                                          <div class="subjectImgsContainer">
                                            <div v-for="(subjectImg,index3) in subject.subjectImgs" :key="index3">
                                              <label :for="'myfile' + index1 + index2 + index3" class="file-label"><img
                                                  alt=""
                                                  :src="subjectImg.imageUri"
                                                  style="height: 70px;"></label>
                                              <input class="file" :id="'myfile' + index1 + index2 + index3" type="file"
                                                     @change="sync($event, subjectImg)" accept=".jpg, .jpeg">
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colspan="2" style="text-align:left">
                                          <input
                                              @click='subject.subjectImgs.push({"ord": subject.subjectImgs.length, "imageUri":"https://bookingeasy.ru/images/add.png"})'
                                              type="button"
                                              class="btn btn-sm btn-success main-btn text-uppercase" value="Добавить">
                                        </td>
                                      </tr>

                                      <!--tr>
                                        <td class="cell"></td>
                                        <td class="cell"><input @click="subject.delete=true" type="button"
                                                                class="btn btn-sm btn-danger text-uppercase" value="Удалить">
                                        </td>
                                      </tr-->
                                    </table>
                                    <br>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="cell"><p class="text-danger">{{ accountErorr2 }}</p></td>
                              <td class="cell"><input @click="setAccountGroups()" type="button"
                                                      class="btn btn-sm btn-success main-btn text-uppercase"
                                                      value="Сохранить">
                              </td>
                            </tr>
                          </table>
                          <br>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>


            <div class="ddd-tab-content" v-if="tab==2">
              <table>
                <tr>
                  <td class="cell">Старый пароль:</td>
                  <td class="cell"><input type="password" v-model="psw.oldPsw"></td>
                </tr>
                <tr>
                  <td class="cell">Новый пароль:</td>
                  <td class="cell"><input type="password" v-model="psw.newPsw1"></td>
                </tr>
                <tr>
                  <td class="cell">Новый пароль (ещё раз):</td>
                  <td class="cell"><input type="password" v-model="psw.newPsw2"></td>
                </tr>
                <tr>
                  <td class="cell"><p class="text-danger">{{ pswErorr }}</p></td>
                  <td class="cell"><input @click="updatePsw()" type="button"
                                          class="btn btn-sm btn-success main-btn text-uppercase" value="Сменить пароль">
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end lk -->
</template>

<style>
#lk .overlay {
  background: none;
}

#lk {
  /*background: url('../images/b1.jpg') no-repeat;*/
  background-size: cover;
  text-align: center;
  /*height: calc(100% - 145px);*/
}

#lk .title {
  font-size: 20px;
  text-align: left;
  font-weight: 500;
}

#lk table {
  background: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
}

#lk .cell {
  height: 52px;
  padding: 1px 16px;
  text-align: right;
}

#lk .btn {
  margin-left: 16px;
}

#lk .file-label {
  cursor: pointer;
}

#lk .file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#lk .main-setings input {
  width: 200px;
}

#lk .main-setings select {
  width: 200px;
}

.ddd-tab-head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ddd-tab-tab {
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  height: 48px;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.25px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  background-color: #e1f4fd;
}

.ddd-tab-tab:hover {
  background-color: #e1f4fd;
}

.ddd-tab-active {
  background-color: #84d2f5;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #165490;

}

.subjectImgsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>

<script>
import axios from 'axios'
import Toast from '@/js/toast.min.js'
import LoginView from '@/views/LoginView.vue'
import {global} from "@/global.js";

export default {
  name: 'LkSetupView',
  components: {
    LoginView
  },
  data() {
    return {
      global: global,
      tab: 0,
      tab1: 0,
      tab2: 0,
      account: {subjects: [], groups: []},
      accountErorr1: "",
      accountErorr2: "",
      psw: {},
      pswErorr: "",
      pswSuccess: "",
    }
  },

  mounted() {
    global.value.lk = true;
    if (global.value.authenticated) {
      this.load();
    } else {
      setTimeout(function () {
        if (global.value.authenticated) {
          this.load();
        }
      }.bind(this), 1000);
    }
  },
  watch: {
    'account.mode'(newMode, oldMode) {
      if (newMode !== oldMode) {
        if (newMode == 0) {
          this.account.mainText = "Выберите дату и свободные часы в интересующим вас номере, забронировать можно по телефону";
        }
        if (newMode > 0) {
          this.account.mainText = "Выберите дату и свободные часы в интересующим вас номере и нажмите \"Забронировать\"";
        }
      }
    }
  },

  methods: {
    load() {
      this.getAccount();
    },
    sync(e, subject) {
      e.preventDefault();
      const maxAllowedSize = 1 * 1024 * 1024;
      if (e.target.files[0].size > maxAllowedSize) {
        alert(`Максимальный размер картинки 1 мегабайт`);
        return;
      }
      var formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios.post(global.value.url + "lk/uploadFile", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            subject.imageUri = response.data.text;
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      //
      //this.account
    },
    addSubject(group) {
      this.tab2 = group.subjects.length;
      group.subjects.push({
        "minTime": 60,
        "maxTime": 600,
        "startHour": 0,
        "endHour": 24,
        "imageUri": "https://bookingeasy.ru/images/add.png",
        "enabled": true,
        "delete": false
      });
    },
    addGroup() {
      this.tab1 = this.account.groups.length;
      this.account.groups.push({"subjects": [], "num": this.account.groups.length, "enabled": true, "delete": false});
    },
    getAccount() {

      axios.get(global.value.url + "lk/account").then(response => {
        this.account = response.data;
        /*if (document.getElementById("admin-booking-widget") == null) {
          var script = document.createElement('script');
          script.id = "admin-booking-widget";
          script.src = 'https://bookingeasy.ru/api/widget?type=1&mode=3&admin=true&key=' + this.account.key;
          document.head.appendChild(script);
        }*/
        if (this.account.groups.length == 0) this.tab = 1;
      });
    },
    setAccount() {
      if (this.account.mode == 2) {
        if (this.account.acquiring == null) {
          new Toast({
            title: false,
            text: 'Поле "Интернет-Эквайринг" нужно заполнить',
            theme: 'danger',
            autohide: true,
            interval: 10000
          });
          return;
        }
        if (this.account.terminalKey == null) {
          new Toast({
            title: false,
            text: 'Поле "Терминал" нужно заполнить',
            theme: 'danger',
            autohide: true,
            interval: 10000
          });
          return;
        }
        if (this.account.bookingAmount == null) {
          new Toast({
            title: false,
            text: 'Поле "Сумма для бронирования" нужно заполнить',
            theme: 'danger',
            autohide: true,
            interval: 10000
          });
          return;
        }
        if (this.account.siteUrl == null) {
          new Toast({
            title: false,
            text: 'Поле "Ссылка на ваш сайт" нужно заполнить',
            theme: 'danger',
            autohide: true,
            interval: 10000
          });
          return;
        }
        if (this.account.taxation == null) {
          new Toast({
            title: false,
            text: 'Поле "Система налогообложения" нужно заполнить',
            theme: 'danger',
            autohide: true,
            interval: 10000
          });
          return;
        }
        if (this.account.tax == null) {
          new Toast({
            title: false,
            text: 'Поле "Ставка НДС нужно" нужно заполнить',
            theme: 'danger',
            autohide: true,
            interval: 10000
          });
          return;
        }
      }
      this.loading = true;
      this.accountErorr1 = "";
      axios
          .put(global.value.url + "lk/account", this.account)
          .then(response => {
            if (!response.data.success) {
              this.accountErorr1 = response.data.text;
              this.errored = true;
              return;
            }
            this.errored = false;
            this.getAccount();
            new Toast({
              title: false,
              text: 'Настройки сохранены',
              theme: 'success',
              autohide: true,
              interval: 10000
            });
          })
          .catch(() => {
            this.accountErorr1 = "Ошибка сохранения";
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    setAccountGroups() {
      this.loading = true;
      this.accountErorr2 = "";
      axios
          .put(global.value.url + "lk/account-groups", this.account)
          .then(response => {
            if (!response.data.success) {
              this.accountErorr2 = response.data.text;
              this.errored = true;
              return;
            }
            this.errored = false;
            this.getAccount();
            new Toast({
              title: false,
              text: 'Настройки сохранены',
              theme: 'success',
              autohide: true,
              interval: 10000
            });
          })
          .catch(() => {
            this.accountErorr2 = "Ошибка сохранения";
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    updatePsw() {
      this.loading = true;
      this.pswErorr = "";
      axios
          .put(global.value.url + "lk/updatePsw", this.psw)
          .then(response => {
            if (!response.data.success) {
              this.pswErorr = response.data.text;
              this.errored = true;
              return;
            }
            this.errored = false;
            new Toast({
              title: false,
              text: 'Пароль сохранён',
              theme: 'success',
              autohide: true,
              interval: 10000
            });
          })
          .catch(() => {
            this.pswErorr = "Ошибка обновления пароля";
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  }
}
</script>
