<template>
  <div id="cookie_notification" v-if="cookieNotification">
    <p>Этот сайт использует cookie.</p>
    <!--p>Этот сайт использует cookie. Продолжая работу с
      сайтом, Вы разрешаете использование cookie-файлов. Вы всегда можете отключить файлы cookie в настройках Вашего
      браузера.</p-->
    <button class="btn btn-sm btn-success text-uppercase button cookie_accept" @click="setNotificationCookie()">Принять</button>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      cookieNotification: false
    }
  },

  mounted() {
    this.checkCookies();
  },

  methods: {
    checkCookies() {
      let cookieDate = localStorage.getItem('cookieDate');
      // Если записи про кукисы нет или она просрочена на 1 год, то показываем информацию про кукисы
      if (!cookieDate || (+cookieDate + 31536000000) < Date.now()) {
        this.cookieNotification = true;
      }
    },
    setNotificationCookie(){
      // При клике на кнопку, в локальное хранилище записывается текущая дата в системе UNIX
      localStorage.setItem('cookieDate', Date.now());
      this.cookieNotification = false;
    }
  }
}
</script>