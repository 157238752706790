<template>
  <!-- start agreement -->
  <section id="agreement1">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Договор публичной оферты</h1>
          <p><em>Договор на предоставление доступа к интернет-сервису «Bookingeasy.ru»</em></p>
          <p>Настоящий договор на предоставление доступа к интернет-сервису
            «BookingEasy» (далее - Договор), заключается между индивидуальным
            предпринимателем Солуяновым Е. Е., именуемым в дальнейшем "Исполнитель" и
            любым лицом, которое становится пользователем сайта, расположенного в
            сети Интернет по адресу: <a href="https://bookingeasy.ru/">https://bookingeasy.ru</a>,
            далее - Сайт, после принятия условий Договора, в дальнейшем именуемым
            "Пользователь". По тексту Договора Исполнитель и Пользователь совместно
            именуются "Стороны", а по отдельности - "Сторона".</p>
          <p>В соответствии со ст.ст. 435, 437 Гражданского кодекса Российской
            Федерации (ГК РФ) Договор одновременно признается публичной офертой. На
            основании ст. 438 ГК РФ, безусловным принятием (акцептом) условий
            Договора считается факт регистрации на Сайте, в силу чего Договор не
            требует двустороннего подписания и действителен в электронном виде.</p>
          <p><strong>1. Определения и термины</strong></p>
          <p>В Договоре, если из его текста прямо не вытекает иное, следующие слова и выражения будут иметь указанные ниже значения:</p>
          <p>1.1. "Сайт" - совокупность программных и аппаратных средств для ЭВМ,
            обеспечивающих публикацию для всеобщего обозрения информации и данных,
            объединённых общим целевым назначением, посредством технических средств,
            применяемых для связи между ЭВМ в сети Интернет. Под Сайтом в Договоре
            понимается Сайт, на котором расположена Система регистрации и
            авторизации пользователей, находящаяся в сети Интернет по адресу: <a href="https://bookingeasy.ru/">https://bookingeasy.ru</a>. Интернет-сервис&nbsp;«BookingEasy» также доступен по адресам: <a href="https://booking-easy.ru/">https:/booking-easy.ru</a>.</p>
          <p>1.2. "Владелец Виджета", "Исполнитель" -&nbsp;ИП Солуянов Е.Е.
            (ОГРНИП: 323527500081110), является администратором домена bookingeasy.ru и
            домена booking-easy.ru</p>
          <p>1.3. "Виджет" - графический элемент или модуль, встраиваемый на сайт Пользователя для отображения информации.</p>
          <p>1.4. "Пользователь" - дееспособное физическое лицо, пользователь сети
            Интернет и, в частности, Виджета, и имеющий свою личную страницу
            (профиль/аккаунт) на Сайте, одновременно принявший условия Договора.</p>
          <p>1.5. "Профиль" ("Аккаунт") - личная страница Пользователя на Сайте, доступная Пользователю при вводе учётной информации.</p>
          <p>1.6. "Учётная информация" - уникальное имя Пользователя (логин) и
            пароль для входа на Сайт, указываемые Пользователем при регистрации на
            Сайте.</p>
          <p>1.7. Период доступа - определённый срок действия авторизованного
            доступа к профилю Пользователя на сайте bookingeasy.ru, предоставляемый
            Исполнителем после соответствующей оплаты Пользователем. Период доступа
            определяется Пользователем самостоятельно.</p>
          <p>1.8. Тарифный план выбирается на основе последнего пополнения. Информация о
            тарифах расположена на Сайте в разделе цены.</p>
          <p>1.9. После регистрации пользователя в системе абонентская плата списывается со следующего месяца, оплата за дополнительные платные услуги
            списывается согласно тарифу.</p>
          <p><strong>2. Предмет договора</strong></p>
          <p>2.1. По настоящему договору Исполнитель на возмездной основе
            обязуется предоставить Пользователю доступ к
            интернет-сервису&nbsp;«BookingEasy».</p>
          <p>3. Заключение Договора. Срок действия</p>
          <p>3.1. Договор считается заключённым на условиях, изложенных в настоящей оферте в момент регистрации Пользователя на Сайте.</p>
          <p>3.2. Оферта считается акцептованной Пользователем с момента регистрации его на Сайте.</p>
          <p>3.3. Срок действия Договора равен периоду доступа, выбранному
            Пользователем самостоятельно.</p>
          <p>3.4. Предоставление доступа по Договору начинается не позднее двадцати четырёх часов после акцепта Пользователем Договора.</p>
          <p>3.5. Действие Договора может быть приостановлено Исполнителем в одностороннем порядке, предусмотренном п. 6.6. Договора.</p>
          <p><strong>4. Права и обязанности Исполнителя</strong></p>
          <p>4.1. Исполнитель обязан:</p>
          <p>4.1.1. Своевременно предоставлять доступ к профилю Пользователя, при
            регистрации в качестве пользователя интернет-сервиса «BookingEasy» на Сайте
            https://bookingeasy.ru;</p>
          <p>4.1.2. осуществлять техническую и технологическую поддержку Пользователя;</p>
          <p>4.1.3. предоставлять бесплатно консультации по использованию и
            функционированию интернет-сервиса «BookingEasy», в том числе по вводу данных,
            их систематизации и учёту;</p>
          <p>4.1.4. предоставлять Пользователю возможность самостоятельно публиковать информацию в своём профиле.</p>
          <p>4.1.5. не разглашать третьим лицам Учётную информацию Пользователя.</p>
          <!--p>!!!!!!4.1.6. обеспечивать круглосуточную работоспособность сервера, на
            котором расположен Сайт, за исключением времени проведения
            профилактических работ. О времени проведения профилактических работ
            Исполнитель оповещает Пользователя путём публикации информации на Сайте
            либо путём перенаправления Пользователя на страницу bookingeasy.ru/update.
            При этом Исполнитель допускает наличие технических неполадок или
            периодов доработки интернет-сервиса, из-за чего сервер может быть
            недоступен, но не более одних суток. В случае, если сервер недоступен
            более суток, абонентская плата за этот месяц не взимается с Пользователя до
            момента возобновления работы интернет-сервиса.</p-->
          <p><strong>4.2. Исполнитель вправе:</strong></p>
          <p>4.2.1. Осуществлять улучшения, необходимые настройки, изменения,
            дополнения, доработку, менять дизайн Виджета, Виджета без какого-либо
            согласия Пользователя, при этом Пользователь не вправе вмешиваться в эту
            работу Исполнителя;</p>
          <p>4.2.2. Проводить технические и технологические работы, связанные с
            функционирование и работоспособностью сайта bookingeasy.ru, виджета, и всех
            связанных с ним компонентов и модулей;</p>
          <p>4.2.3. Распоряжаться статистической информацией, связанной с
            функционированием Виджета, а также информацией пользователей для
            обеспечения адресного показа рекламной информации различным аудиториям
            потенциальных пользователей Виджета;</p>
          <p>4.2.4. Направлять Пользователю информацию о развитии Виджета и его
            сервисов; отправлять sms, Telegram сообщения с целью информирования о кодах
            активации, проведения опросов о качестве работы Виджета, предоставления и
            запросов прочей информации, а также рекламировать собственную
            деятельность и услуги, Пользователь дает согласие на получение такой
            информации.</p>
          <p>4.2.5. Ссылаться на Пользователя, как на пользователя системы
            бронирования и/или Услуг, в любой форме и на любом носителе (в т.ч. на
            Сайте и любых других сайтах и/или в рекламных материалах Исполнителя).</p>
          <p>4.2.6. По истечении 1 (одного) календарного месяца с момента
            расторжения или прекращения срока действия Договора Исполнитель имеет
            право удалить все данные Пользователя из сервиса без возможности
            восстановления и освобождается от любых обязательств, связанных с
            Данными Заказчика.</p>
          <p>4.2.7. В момент, когда баланс аккаунта Пользователя становиться менее
            1000 руб, система посылает на основной Email пользователя и в Telegram
            соответствующее уведомление, с предложением пополнить баланс в системе.</p>
          <p>4.2.8. В случае если баланс аккаунта пользователя становиться менее 0
            руб, система включает таймер автоматической блокировки и позволяет
            Пользователю пользоваться системой в течении 7 дней без ограничений,
            после аккаунт пользователя автоматически блокируется, и выключаются все
            функции, до момента пока баланс не станет положительным.</p>
          <p>4.2.9. В случае если задолженность Пользователя перед Исполнителем по
            настоящему Договору превысит 3000 руб., модуль автоматически
            выключается, и Исполнитель имеет право удалить все данные Пользователя
            из сервиса без возможности восстановления и освобождается от любых
            обязательств по настоящему Договору, который считается расторгнутым с
            данного момента.</p>
          <!--p>4.2.10. В случаее если баланс аккаунта пользователя становиться
          отрицательным и задолженность пользователя становиться более 2000 руб
          и(или) пользователь не активен более 1 года и(или) пользователь не
          активировал свой аккаунт в течение 7 дней после регистрации, аккаунт
          Пользователя автоматически помечается на удаление, пользователю на email
           отправляется уведомление об удалении аккаунта, непосредственное
          удаление производиться через 7 дней. Уведомления отправляются только на
          подтвержденные пользователями Email адреса.</p-->
          <p>4.2.10. Удалить, изменить информацию, размещенную Пользователем на
            Сайте в случае, если такая информация нарушает требования
            законодательства РФ, запреты и ограничения настоящей Оферты.</p>
          <p>4.2.11. Изменять условия Тарифных планов в зависимости от
            экономических условий иных причин, прекращать действия неактуальных
            Тарифных планов. Об изменениях в Тарифных планах Исполнитель будет
            уведомлять пользователей обо всех изменениях и новостях проекта в
            паблике социальной сети «В контакте» https://vk.com/bookingeasy, при этом
            Пользователь должен самостоятельно отслеживать актуальную информацию о
            проекте, о смене Тарифных планов и др.</p>
          <p>4.2.12. Отказать Пользователю в доработке Виджета и его сервисов без объяснения причин.</p>
          <p><strong>5. Права и обязанности Пользователя</strong></p>
          <p>5.1. Пользователь обязан:</p>
          <p>5.1.1. Полностью ознакомиться с условиями Договора до момента
            регистрации на Сайте, самостоятельно знакомиться с новой редакцией
            Договора на Сайте, при обновлении его редакции. В случае, если
            Пользователя не устраивает условия оказания услуг, он в любой момент
            может отказаться от пользования услугами, удалить свой аккаунт и
            размешенную информацию, потребовать вернуть неиспользованные денежные
            средства направив заявку через личный кабинет или по электронной почте.</p>
          <p>5.1.2. Своевременно оплачивать услуги Исполнителя в соответствии с
            выбранным им Тарифным планом и своевременно пополнять личный счет на
            Сайте;</p>
          <p>5.1.3. Не нарушать умышленно нормальное функционирование Виджета и оказание услуги по предоставлению доступа к интернет-сервису;</p>
          <p>5.1.4. Не передавать свою Учётную информацию третьим лицам;</p>
          <p>5.1.5. Ознакомиться и принять все условия соглашения об обработке персональных данных;</p>
          <p>5.1.6. Не загружать, не хранить, не публиковать, не распространять на Сайте любую информацию, которая:</p>
          <ul>
            <li>Содержит угрозы, дискредитирует, оскорбляет, порочит честь и
              достоинство или деловую репутацию, или нарушает неприкосновенность
              частной жизни других пользователей или третьих лиц;</li>
            <li>Нарушает права несовершеннолетних лиц;</li>
            <li>Является вульгарной или непристойной, содержит нецензурную лексику,
              содержит порнографические изображения и тексты или сцены сексуального
              характера с участием несовершеннолетних;</li>
            <li>Содержит сцены насилия, либо бесчеловечного обращения с животными;</li>
            <li>Содержит описание средств и способов суицида, любое подстрекательство к его совершению;</li>
            <li>Пропагандирует и (или) способствует разжиганию расовой, религиозной,
              этнической ненависти или вражды, пропагандирует фашизм или идеологию
              расового превосходства;</li>
            <li>Содержит экстремистские материалы;</li>
            <li>Пропагандирует преступную деятельность или содержит советы, инструкции или руководства по совершению преступных действий;</li>
            <li>Содержит информацию ограниченного доступа, включая, но не
              ограничиваясь, государственной и коммерческой тайной, информацией о
              частной жизни третьих лиц (в т. ч., персональные данные);</li>
            <li>Содержит рекламу или описывает привлекательность употребления
              алкоголя и (или) наркотических веществ, в том числе "цифровых
              наркотиков" (звуковых файлов, оказывающих воздействие на мозг человека
              за счёт бинауральных ритмов), информацию о распространении наркотиков,
              рецепты их изготовления и советы по употреблению;</li>
            <li>Носит мошеннический характер;</li>
            <li>Нарушает иные права и интересы граждан и юридических лиц или требования законодательства Российской Федерации;</li>
          </ul>
          <p>5.1.6. Не использовать программное обеспечение и не осуществлять
            действия, направленные на нарушение нормального функционирования
            Виджета, его сервисов и (или) персональных страниц пользователей; не
            загружать, не хранить, не публиковать, не распространять и не
            предоставлять доступ или иным образом использовать вирусы, трояны и
            другие вредоносные программы; не использовать без специального на то
            разрешения Исполнителя автоматизированные скрипты (программы) для сбора
            информации на Сайте и (или) взаимодействия с Сайтом и его сервисами;</p>
          <p>5.1.7. Не пытаться получить доступ к логину и паролю другого
            Пользователя, в том числе, но не исключительно, обманом, взломом
            профилей других пользователей и прочее;</p>
          <p>5.1.8. Предоставить информацию, необходимую для создания Виджета, в течение 3 (трёх) рабочих дней с даты заключения Договора;</p>
          <p>5.1.9. Согласовать и разместить Виджет на своём веб-сайте в течение 3
            (трёх) рабочих дней после получения кода встраивания в личном кабинете.</p>
          <p>5.1.10. Пользователь информирован и согласен с тем, что IP-адрес
            персонального ЭВМ Пользователя фиксируется техническими средствами
            Исполнителя, и в случае совершения незаконных действий, в том числе
            действий, нарушающих интеллектуальные права третьих лиц, ответственным
            за указанные незаконные действия признается владелец персонального ЭВМ,
            определяемого техническими средствами Исполнителя по принадлежности
            IP-адреса.</p>
          <p>5.1.11. В случае если Пользователь использует возможности Виджета для
            банковских расчетов (эквайринг) с третьими лицами в своих интересах, то
            он должен соблюдать требования Федерального закона РФ № 54-ФЗ «О
            применении контрольно-кассовой техники при осуществлении расчетов в
            Российской Федерации» от 22.05.2003 г. (подключить онлайн-кассу,
            формировать электронно-расчетные документы (чеки) и т.п.). При этом
            Пользователь обязан самостоятельно проверить правильность и актуальность
            используемых им данных для платежей, правильность работы онлайн-кассы,
            формирования электронно-расчетных документов.</p>
          <p><strong>5.2. Пользователь вправе:</strong></p>
          <p>5.2.1. Пользоваться услугами Исполнителя в соответствии с условиями настоящего Договора.</p>
          <p>5.2.2. Требовать от Исполнителя своевременного исправления возникающих технических и технологических неполадок.</p>
          <p>5.2.3. Вносить предложения по улучшению функциональной возможностей интернет-сервиса «BookingEasy.ru».</p>
          <p>5.2.4. В случае возникновения в работе Сервиса проблем технического
            характера, а также в случае получения Пользователем сообщений,
            являющихся несанкционированной рекламной рассылкой либо содержащих
            запрещенные настоящим Соглашением Материалы, в том числе угрозы или
            файлы с подозрением на вирус, в случае если Пользователь обнаруживает
            факты, дающие основания полагать, что его доступ был использован
            кем-либо несанкционированно, Пользователь имеет право обратиться к
            Исполнителю для выяснения ситуации и принятия необходимых мер.</p>
          <p>5.2.5. На свое усмотрение удалять ставшей неактуальную информацию на Сайте, в том числе и размещенную Пользователем.</p>
          <p><strong>6. Стоимость услуг по Договору. Порядок расчётов</strong></p>
          <p>6.1. Цена договора соответствует стоимости периода доступа
            (соответствует календарному месяцу) к интернет-сервису «BookingEasy.ru»,
            согласно сумме оплаты Пользователем и Тарифному плану.</p>
          <p>6.2. Актуальные условия Тарифных планов, предлагаемых Исполнителем
            Пользователям, публикуются на странице https://bookingeasy.ru/#pricing</p>
          <p>6.3. Исполнитель по заявкам Пользователя при наличии возможности
            может оказать дополнительные услуги, не входящие в Тарифный план, а том
            числе, но не ограничиваясь доработкой виджета и т.п. В этом случае
            Стороны согласуют стоимость таких услуг (работ) дополнительно.</p>
          <!--p>!!!!6.4. В случае подключения Пользователем сервиса онлайн платежей, при
          поступлении оплаты заказа со стороны клиента Пользователя, подлежит
          списанию сумма комиссии за соответствующий онлайн платёж. Списание
          осуществляется из суммы денежных средств, внесённых в качестве оплаты по
           Договору. Размеры комиссий за онлайн платежи размещены по адресу: <a href="https://bookingeasy.ru/prices">https://bookingeasy.ru/prices</a>.</p-->
          <!--p>!!!!!6.5. Плата за отправку sms-сообщения клиенту Пользователя подлежит
          списанию из суммы денежных средств, внесённых в качестве оплаты по
          Договору, исходя из количества направленных сообщений. Размеры платы за
          отправку sms-сообщения размещены по адресу: https://bookingeasy.ru/#pricing .</p-->
          <p>6.4. При достижении отрицательного значения денежных средств по
            результатам ежемесячного списания, действие
            Договора приостанавливается. Приостановление действия Договора
            подразумевает под собой отключение Виджета, и иных функциональных
            возможностей интернет-сервиса «BookingEasy.ru», прекращение обязательств
            Исполнителя.</p>
          <p>6.5. Пользователь вправе отключить Виджет. В этом случае ежемесячная
            абонентская плата не списывается.
            Отключение Виджета влечёт прекращение его отображения и ряд ограничений,
            сопряжённых с работой системы.</p>
          <p>6.6. Возобновление работы Виджета является бесплатным.</p>
          <p>6.7. В случае, если Пользователь намерен прекратить действие Договора
            или вернуть внесенные денежные средства, как полностью, так и частично,
            то неиспользованные денежные средства со счета Пользователя могут быть
            возвращены ему по соответствующему заявлению. При этом расчёт
            неизрасходованного остатка денежных средств, происходит за вычетом:</p>
          <ul>
            <li>Необходимых расходов на услуги (комиссий) банков, платёжных систем;</li>
            <li>Суммы на покрытие расходов исполнителя в размере 15% (пятнадцать
              процентов) от суммы, имевшейся до вычета расходов на услуги (комиссий)
              банков, платёжных систем.</li>
            <li>Общей суммы начисленных бонусов, начисленных при пополнении баланса</li>
            <!--li>сумма, уплаченная Исполнителем лицу, осуществившему действия по
            привлечению Пользователя (агенту) согласно агентскому договору в рамках
            предоставления доступа к интернет-сервису «BookingEasy.ru»:
            https://bookingeasy.ru/doc/partner_oferta), при условии, что Пользователь
            заключил Договор в результате выполнения агентом действий по привлечению
             Пользователя). В случае, если Пользователь заключил Договор в
            отсутствие предварительных действий того или иного агента,
            действовавшего в рамках агентского договора, данная сумма не подлежит
            вычету для расчёта остатка, подлежащего возвращению Пользователю.</li-->
          </ul>
          <p>6.8. Заказчик имеет право в любой момент подать заявку на удаление
            своей учетной записи и всех связанных с ней данных, хранимых на Сервисе.
            Удаление учетной записи и всех связанных с ней данных, хранимых на
            Сервисе, производится в течение 7 дней с даты получения заявки.</p>
          <p>6.9. При безналичной оплате Услуг посредством эквайринга или при
            оплате Услуг банковской картой Пользователями, которые являются
            юридическими лицами, подтверждающая документация не выдаётся.</p>
          <!--p>!!!!!Кроме вышеуказанного, вычету подлежит сумма, уплаченная Исполнителем
          лицу, осуществившему действия по привлечению Пользователя (агенту)
          согласно <a href="https://doc.bookingeasy.ru/docs/partners/oferta">агентскому договору</a>
           в рамках предоставления доступа к интернет-сервису «BookingEasy», при
          условии, что Пользователь заключил Договор в результате выполнения
          агентом действий по привлечению Пользователя). В случае, если
          Пользователь заключил Договор в отсутствие предварительных действий того
           или иного агента, действовавшего в рамках агентского договора, данная
          сумма не подлежит вычету для расчёта остатка, подлежащего возвращению
          Пользователю.</p-->
          <!--p>6.10. В случае если Исполнитель изменяет тарифные планы Пользователь
          вправе продолжить использовать тот тарифный план, который выбрал.</p-->
          <p>6.10. Стороны согласовали, что минимальная сумма, которую
            Пользователь перечисляет Исполнителю в целях оплаты услуг по настоящей
            Оферте при онлайн оплате не может быть менее 500.00 руб, при оплате по
            счету минимальная сумма оплаты составляет 1000.00 руб</p>
          <p><strong>7. Условия о конфиденциальности и исключительности</strong></p>
          <p>7.1. Данные и средства, предоставленные Исполнителем Пользователю, в
            том числе, "логин" и "пароль", необходимые для доступа к Профилю
            Пользователя, а также данные, фиксируемые непосредственно в
            интернет-сервисе «BookingEasy», являются конфиденциальными и не
            распространяются третьим лицам ни Исполнителем, ни Пользователем, кроме
            случаев, предусмотренных законодательством Российской Федерации.</p>
          <p>7.2. Права собственности и иные имущественные права, относящиеся к
            интернет-сервису «BookingEasy», в том числе, все права на результаты
            интеллектуальной деятельности, используемые в составе
            интернет-сервиса&nbsp;«BookingEasy» (независимо от того, зарегистрированы они
            или нет) принадлежат Исполнителю.</p>
          <p><strong>8. Обработка персональных данных</strong></p>
          <p>8.1. Присоединяясь к Договору и оставляя свои данные на Сайте, Пользователь:</p>
          <ul>
            <li>Подтверждает, что все указанные им данные принадлежат лично ему;</li>
            <li>Подтверждает и признаёт, что им внимательно в полном объёме прочитан
              Договор и условия обработки Исполнителем его персональных данных; текст
              Договора, в т.ч., в части условий обработки персональных данных ему
              понятны;</li>
            <li>Даёт согласие на обработку Исполнителем предоставляемых в составе
              информации персональных данных в целях заключения между ним и
              Исполнителем Договора, а также его последующего исполнения;</li>
            <li>Выражает согласие с условиями обработки персональных данных без оговорок и ограничений;</li>
            <li>Подтверждает, что его согласие на обработку персональных данных является конкретным, информированным и осознанным.</li>
          </ul>
          <p>8.2. Пользователь дает своё согласие на обработку его персональных
            данных, а именно, совершение действий, предусмотренных п. 3 ст. 3
            Федерального закона от 27.07.2006 № 152-ФЗ "О персональных данных", и
            подтверждает, что, давая такое согласие, он действует свободно, своей
            волей и в своем интересе.</p>
          <p>8.3. Пользователь даёт своё согласие на возможное осуществление
            трансграничной передачи персональных данных в соответствии со ст. 12
            Федерального закона от 27.07.2006 № 152-ФЗ "О персональных данных".</p>
          <p><strong>9. Ответственность Сторон</strong></p>
          <p>9.1. В случае невозможности исполнения своих обязанностей вследствие
            непреодолимой силы (форс-мажора), Исполнитель или Пользователь (в
            зависимости от того, на ком лежит обязанность) не несёт ответственности
            за неисполнение данной обязанности, но обязан уведомить вторую Сторону о
            невозможности такого исполнения в разумный срок.</p>
          <p>9.2. Исполнитель не несёт ответственности за доставку sms-сообщений клиентам Пользователя.</p>
          <p>9.3. Исполнитель не несет ответственности за возможные убытки,
            упущенную выгоду Пользователя, возникшие из-за пользования сервисами
            Виджета, а также за негативные возможные последствия для Пользователя
            (требования государственных органов, третьих лиц и т.п.), возникшие
            из-за неисполнения, ненадлежащего исполнения им своих обязательств по
            настоящему Договору.</p>
          <p>9.4. Исполнитель не несет ответственности, если в результате работы
            программных средств поисковых систем, рекламных систем, антивирусных
            и/или иных программ будет заблокирована работа его Виджета (и или
            пощадки где установлен виджет) по причинам, не зависящим от Исполнителя.
            В этом случае Исполнитель окажет Пользователю содействие в установлении
            причин такой блокировки и в восстановлении работоспособности Виджета.</p>
          <p>9.5. Исполнитель не несет ответственности за возможные убытки,
            упущенную выгоду Пользователя, возникшие из-за программных ошибок, сбоев
            в работе сервисов Виджета, а также за негативные возможные последствия
            для Пользователя, возникшие из-за таких ошибок, сбоев, но окажет
            максимальное и оперативное содействие в их устранении и отладке их
            работы.</p>
          <p>9.6. Исполнитель не несёт ответственности за использование (как
            правомерное, так и неправомерное) третьими лицами информации,
            размещенной Пользователем на Сайте.</p>
          <p>9.7. Исполнитель не несет ответственности за вред, причинённый
            Пользователю, в результате внесённых в программный код Виджета изменений
            третьими лицами, вирусными программами, неправильным или неправомерным
            использованием ПО.</p>
          <p>9.8. Исполнитель не гарантирует правильную работу Виджета из-за
            имеющихся ограничений и/или системных ошибок конкретных операционных
            систем, согласно информации, представленной разработчиками данных
            операционных систем, из-за конфликтов с иным программным обеспечением,
            установленным на оконечное оборудование Пользователя.</p>
          <p>9.9. Исполнитель не гарантирует правильную работу Виджета из-за
            имеющихся ограничений и/или системных ошибок оконечного оборудования
            Пользователя.</p>
          <p>9.10. Пользователь самостоятельно отвечает за информацию, размещенную
            им, либо третьими лицами при использовании Виджета, соглашается с тем,
            что в течение 10 дней с момента первого требования возместит Исполнителю
            все документально подтвержденные убытки, понесенные Исполнителем в
            связи с использованием Пользователем Виджета и его сервисов, в том
            числе, но не ограничиваясь, нарушением прав (в том числе
            интеллектуальных, информационных и т.д.) третьих лиц.</p>
          <p>9.11. Исполнитель не несет ответственности за неполученную прибыль,
            понесенные убытки, репутационные издержки Пользователя из-за
            использования Виджета.</p>
          <p>9.12. Исполнитель не несёт ответственности за содержание и наполнение Виджета.</p>
          <p>9.13. Исполнитель предупреждает, что в работе Сайта, Виджета возможны
            сбои из-за действий провайдеров, энергетических компаний, ошибок в
            программном обеспечении. В этом случае Исполнитель примет все возможные
            меры по восстановлению их работоспособности. При этом ответственность
            Исполнителя на такие сбои не распространяются.</p>
          <p><strong>10. Иные условия</strong></p>
          <p>10.1. По всем вопросам, не урегулированным Договором, а также при
            разрешении споров, возникших в процессе его исполнения, Стороны
            руководствуются действующим законодательством Российской Федерации.</p>
          <p>10.2. Все возникающие из Договора споры разрешаются, прежде всего,
            путём переговоров. В случае недостижения соглашения в результате
            переговоров, Сторонами первоначально используется претензионный порядок
            разрешения споров, а затем - судебный, по месту нахождения Исполнителя.</p>
          <p>10.3. Уведомления, которые упоминаются в тексте Договора,
            направляются посредством тех видов связи, которые используются
            Исполнителем и указаны в разделе 11 Договора, а также размещённых на
            сайте bookingeasy.ru и по контактным данным Пользователя, указываемых в
            анкете при регистрации в сети Интернет на сайте bookingeasy.ru.</p>
          <p>10.4. После истечения срока действия выбранного Пользователем периода
            доступа, информация Пользователя сохраняется на сервере Исполнителя в
            течение трёх месяцев. Пользователь вправе безвозмездно получить
            указанные данные в течение трёх месяцев после прекращения действия
            периода доступа по письменному запросу. Если Пользователь не
            воспользовался указанным правом в течение трех месяцев, настоящий
            Договор автоматически считается расторгнутым.</p>
          <p>10.5. Техническая поддержка в форме письменной консультации
            предоставляется только по официальному запросу Пользователя, путём
            отправки электронного сообщения на адрес: <a href="mailto:info@bookingeasy.ru">info@bookingeasy.ru</a>
            или же воспользовавшись формой обратной связи на официальном сайте
            Исполнителя. Консультация специалиста может быть предоставлена по
            следующим вопросам: регистрация и проблемы при ее прохождении,
            функционирование Сервиса и его инструментов, восстановление утраченного
            пароля доступа. Не предоставляются консультации по вопросам настройки
            оборудования, программного обеспечения или Интернет-доступа Пользователя
            или иных третьих лиц, а также по другим вопросам, не имеющим отношения к
            работе Сервиса.</p>
          <p>10.6. В случае возникновения в работе Сервиса проблем технического
            характера, а также в случае получения Пользователем сообщений,
            являющихся несанкционированной рекламной рассылкой либо содержащих
            запрещенные настоящим Соглашением Материалы, в том числе угрозы или
            файлы с подозрением на вирус, в случае если Пользователь обнаруживает
            факты, дающие основания полагать, что его доступ был использован
            кем-либо несанкционированно, Пользователь имеет право обратиться к
            Исполнителю для выяснения ситуации и принятия необходимых мер.</p>
          <p>10.7. В процессе развития и улучшения сервиса, внешнее оформление,
            текстовые элементы, структура и положение любых элементов установленного
            модуля на сайте заказчика может изменяться без согласования с
            Пользователем</p>
          <p>10.8. После регистрации пользователя в системе, пользователю дается 7
            дней на активацию своего аккаунта, в противном случае аккаунт удаляется
            автоматически.</p>
          <p><strong>11. Политика конфиденциальности</strong></p>
          <p>11.1. Любая персональная информация, переданная Сторонами друг другу
            при заключении, исполнении настоящей Оферты, является конфиденциальной
            информацией.</p>
          <p>11.2. Стороны обязуются соблюдать действующее положения
            законодательства РФ, регламентирующие правоотношения, связанные с
            установлением, изменением и прекращением режима конфиденциальности в
            отношении персональной информации Сторон и не разглашать
            конфиденциальную информацию третьим лицам, если иное не предусмотрено
            законодательством РФ и настоящей Офертой. Стандарты обработки
            персональной информации, реализованные на Сайте, соответствуют
            требованиям Общих норм защиты данных ЕС (GDPR).</p>
          <p>11.3. Пользователь дает разрешение Владельцу Виджета на сбор, обработку и хранение персональных данных.</p>
          <p>11.4. В целях исполнения обязательств по настоящей Оферте, Владелец Виджета собирает два вида информации о Пользователе:</p>
          <ul>
            <li>Персональную информацию, которую Пользователь сознательно раскрыл Владельцу Виджета;</li>
            <li>Техническую информацию, автоматически собираемую службой поддержки.
              Во время использования Виджета службе технической поддержки
              автоматически становится доступной информация из стандартных журналов
              регистрации сервера (server logs). Сюда входит IP-адрес компьютеров
              пользователей (или прокси-сервера, если он используется для выхода в
              Интернет), имя интернет-провайдера, имя домена, тип браузера и
              операционной системы, информация о сайте, с которого пользователь
              совершил переход на Сайт, дате и времени этих посещений, файлах, которые
              загружаются. Эта информация анализируется программно в агрегированном
              (обезличенном) виде для анализа использования Виджета, и используется
              при разработке предложений по его улучшению и развитию. Связь между
              IP-адресом и персональной информацией Пользователя никогда не
              раскрывается третьим лицам, за исключением тех случаев, когда это
              требуется законодательство страны, резидентом которого является
              Пользователь.</li>
          </ul>
          <p>11.5. Исполнитель серьезно относится к защите частной жизни
            Пользователя и третьих лиц и никогда не предоставляет его персональную
            информацию кому бы то ни было, кроме случаев, когда этого прямо требует
            уполномоченный орган власти страны, резидентом которой он является
            (например, по письменному запросу суда). Вся персональная информация
            Пользователя используются для связи с ним, для анализа использования
            Виджета, для разработки предложений по его улучшению и развитию и может
            быть раскрыта иным третьим лицам только с его разрешения.</p>
          <p>11.6. Исполнитель осуществляет защиту персональной информации
            Пользователя, применяя общепринятые методы безопасности для обеспечения
            защиты информации от потери, искажения и несанкционированного
            распространения. Безопасность реализуется программными средствами
            сетевой защиты, процедурами проверки доступа, применением
            криптографических средств защиты информации, соблюдением политики
            конфиденциальности.</p>
          <!--p><strong>12. Порядок сдачи-приема услуг<br></strong></p>
          <p>12.1. Исполнитель ежемесячно формирует Акт об оказанных услугах (далее — Акт) на общую сумму фактически<br>оказанных в отчетном периоде услуг последним числом текущего месяца и размещает электронные Акты в Личном кабинете Клиента.<br>
          12.2. Акты на бумажном носителе предоставляются Исполнителем Клиенту по индивидуальному запросу Клиента.<br>
          12.3. Стороны договорились, что Акты, размещенные Исполнителем в Личном
          кабинете Клиента, имеют юридическую силу и будут применяться Клиентом
          наравне с оригиналами.<br>
          12.4. В случае наличия у Сторон технической
          возможности, по их договоренности, Исполнитель имеет право направить
          Клиенту документы, указанные в п. 12.1., подписанными со своей стороны
          усиленной квалифицированной электронной подписью (КЭП), посредством
          применяемой системы электронного документооборота. Клиент в этом случае в
           течение 5 (пяти) рабочих дней с даты получения подписанного
          Исполнителем Акта, подписывает его со своей стороны усиленной КЭП.<br>
          12.5. Все споры и разногласия Стороны будут пытаться решить путем переговоров. В случае невозможности<br>разрешить споры и разногласия путем переговоров они подлежат рассмотрению в Арбитражном суде г. Кирова, Кировской области.
          <strong><br></strong></p-->
          <p><strong>12. Реквизиты Исполнителя</strong></p>
          <p>ИП Солуянов Е.Е.<br> Режим работы: пн-пт, с 10 до 17 ч. (Мск)<br> E-mail: <a href="mailto:info@bookingeasy.ru">info@bookingeasy.ru</a><br> ИНН: 526205831324<br> ОГРНИП: 323527500081110</p>


        </div>
      </div>
    </div>
  </section>
  <!-- end agreement -->

</template>

<script>
export default {
  name: 'Agreement1View',
}
</script>
