<template>
    <div>
      <h2>Подключение</h2>
                <p>1. Нужно зарегистрироваться на сайте и войти в личный кабинет.</p>
                <p>2. В разделе "Настройки" нужно добавить группу объектов (сауну, адрес и т.д. ) в ней добавить объекты (номера, офисы и т.д.)</p>
                <p>3. Для того чтоб вам приходили уведомления в Телеграмм пройдите по ссылке <a href="https://t.me/bookingeasybot">https://t.me/bookingeasybot</a> нажмите "ЗАПУСТИТЬ" и введите код {{global.account.botKey}}</p>
                <p>4. Для просмотра своего виджета пройдите по ссылке <a :href="'https://bookingeasy.ru/api?group=0&key=' + global.account.key">https://bookingeasy.ru/api?group=0&key={{global.account.key}}</a> <br>
                  Её можно вставлять в соцсети<br>
                </p>

                <p>5. Для подключения на своем сайте нужно поместить такой код в то место, где хотим чтобы появился виджет<br>
                  &lt;booking-widget&gt;&lt;/booking-widget&gt;<br>
                </p>
                <p>В любом месте на странице нужно вставить скрипт<br>
                  &lt;script src=&#34;https://bookingeasy.ru/api/widget?group=0&key={{global.account.key}}&#34;&gt;&lt;/script&gt;<br>
                </p>
                <p>Параметр key<br>Ваш ключ(менять не нужно)
                </p>
                <p>Параметр group<br>Номер группы объектов(например если подключено несколько саун, у первой будет номер 0, у второй будет 1 и т.д.)
                </p>
                <p>Для подключения в виде ссылки добавляем ссылку<br> &lt;a href=&#34;https://bookingeasy.ru/api?group=0&key={{global.account.key}}&#34;&gt;Забронировать&lt;/a&gt; </p>
    </div>
</template>

<style>

</style>

<script>
import {global} from "@/global.js";

export default {
  name: 'DocTinkoffView',
    data() {
      return {
        global: global
      }
    }
}
</script>
