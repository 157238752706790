<template>
    <div>
      <h2>Инструкция по приему платежей через ЮКасса</h2>
      <p>Зарегистрировать аккаунт в ЮKassa.  <a href="https://yookassa.ru/">сайт ЮKassa</a></p>
      <p>Войти в личный кабинет ЮKassa.</p>
      <p>Пройти идентификацию и загрузку всех документов (до 3-х дней), в настройках способов подключения указываем "API (для самописных сайтов)".</p>
      <p>Ввести выданные настройки в личном кабинете в разделе "Настройки".</p>
      <p>В разделе "Интеграция" "HTTP-уведомления" в поле "URL для уведомлений" вести "https://bookingeasy.ru/api/pay-notification". Также нужно выставить флаг payment.succeeded</p>
      <p>Провести тестовые оплаты, проверить правильность работы от бронирования до подтверждения.</p>
      <p>Подключить онлайн кассу и ОФД к платежной системе.</p>
      <p>Если потребуется помощь в интеграции, можем помочь все наладить, обращайтесь в техподдержку.</p>
      <img src="https://bookingeasy.ru/images/doc/yookassa.jpg" class="img-doc"><br><br>
    </div>
</template>

<style>

</style>

<script>
export default {
  name: 'DocYookassaView'
}
</script>
