<template>
  <!-- start doc -->
  <section id="doc">
    <div>
      <div class="doc-row">
        <div class="doc-menu">
          <router-link to="/doc/docConnectView" v-bind:class="{current: page=='docConnectView' }">Подключение</router-link><br>
          <router-link to="/doc/docCssView" v-bind:class="{current: page=='docCssView' }">Изменение внешнего вида</router-link><br>
          <router-link to="/doc/docTilda" v-bind:class="{current: page=='docTilda' }">Добавление на Tilda сайт</router-link><br>
          <router-link to="/doc/docVk" v-bind:class="{current: page=='docVk' }">Добавление в Вконтакте</router-link><br>
          <router-link to="/doc/docYookassa" v-bind:class="{current: page=='docYookassa' }">Прием платежей через ЮКасса</router-link><br>
          <router-link to="/doc/docTinkoff" v-bind:class="{current: page=='docTinkoff' }">Прием платежей через Тинькофф</router-link><br>
        </div>
        <div class="doc-content">
          <div v-if="page=='docTilda'"><DocTildaView/></div>
          <div v-if="page=='docVk'"><DocVkView/></div>
          <div v-if="page=='docYookassa'"><DocYookassaView/></div>
          <div v-if="page=='docTinkoff'"><DocTinkoffView/></div>
          <div v-if="page=='docCssView'"><DocCssView/></div>
          <div v-if="page=='docConnectView'"><DocConnectView/></div>
        </div>
      </div>
    </div>
  </section>
  <!-- end doc -->
</template>

<style>
    #doc{
        padding-top: 1em;
    }
    .doc-row{
        display: flex;
        flex-direction: row;
    }
    .doc-content{
        width: 75em;
        max-width: 75em;
    }
    .doc-menu{
        width: 25em;
        min-width: 25em;
    }

    .doc-menu a {
      color: #666;
      font-size: 13px;
      font-weight: bold;
      height: 70px;
      line-height: 40px;
      margin-left: 20px;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
    }

    .doc-menu a:hover,
    .doc-menu a:focus,
    .doc-menu a.current {
      color: #28a7e9;
    }

    .img-doc{
        width: 600px;
        border: 3px solid transparent;
        border-image: linear-gradient(90deg, #28c1ea , #2a87ee);
        border-image-slice: 1;
    }
</style>

<script>
import axios from 'axios'
import {global} from "@/global.js";

import DocTildaView from '@/views/doc/DocTildaView.vue'
import DocVkView from '@/views/doc/DocVkView.vue'
import DocYookassaView from '@/views/doc/DocYookassaView.vue'
import DocTinkoffView from '@/views/doc/DocTinkoffView.vue'
import DocCssView from '@/views/doc/DocCssView.vue'
import DocConnectView from '@/views/doc/DocConnectView.vue'

export default {
  name: 'DocView',
  components: {
    DocTildaView,
    DocVkView,
    DocYookassaView,
    DocTinkoffView,
    DocCssView,
    DocConnectView
  },
  mounted() {
        //global.value.lk=true;
    if(global.value.authenticated) {
        this.load();
    }else {
        setTimeout(function () {
            if (global.value.authenticated) {
                this.load();
            }
        }.bind(this), 1000);
    }
  },

  data() {
    return {
      global: global
    }
  },
  props: {
    page: {
      type: String,
      default: "docTilda",
      required: false
    }
  },
  methods: {
      load(){
        this.getAccount();
      },
      getAccount(){
        axios.get(global.value.url + "lk/account").then(response => {
          global.value.account = response.data;
        });
      }
  }
}
</script>
