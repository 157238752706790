<template>
    <div>
      <h2>Инструкция по добавлению виджета на сайт ВКонтакте</h2>
      <p>Есть три варианта добавления бронирования в сообщество.</p>
      <p>1. Добавить как приложение, самый сложный вариант, его опишем ниже.</p>
      <p>2. Добавить как кнопку действия, для этого в настройках группы в разделе "Кнопка действия" выберете тип действия "Открыть сайт",
      текст на кнопке "Забронировать", введите в поле "Ссылка" ссылку:<br>
      &lt;script src=&#34;https://bookingeasy.ru/api/widget?group=0&key={{global.account.key}}&#34;&gt;&lt;/script&gt;</p>
      <p>3. Добавить как ссылку вместо сайта(если его у вас нет), для этого в настройках группы в разделе "Основная информация"
       введите в поле "Адрес" ссылку:<br>
                  &lt;script src=&#34;https://bookingeasy.ru/api/widget?group=0&key={{global.account.key}}&#34;&gt;&lt;/script&gt;</p>
      <img src="https://bookingeasy.ru/images/doc/vk0.jpg" class="img-doc"><br><br>
      <p>Первый и второй вариант работают только когда пользователь зарегистрирован в ВКонтакте.</p>
      <p>Чтобы добавить как приложение, нужно. Перейти в  <a href="https://vk.com/apps?act=manage" target="_blank">https://vk.com/apps?act=manage</a>
      и нажать кнопку "Создать". Ввести название, описание, выбрать категорию "Другое", оставить платформу "Встраиваемое приложение" и нажать "Перейти к настройке приложения".
      Подтвердить номер через код по SMS. Пользовательское соглашение и Политику конфиденциальности можно взять с нашего сайта https://bookingeasy.ru/#/agreement1 и https://bookingeasy.ru/#/agreement3
      Далее нужно выбрать сообщество куда оно будет установлено в разделе "Дополнительно".<br>
      <img src="https://bookingeasy.ru/images/doc/vk1.jpg" class="img-doc"><br><br>
      Далее в справа в меню переходим в раздел "Настройки" и переключаем состояние на "Доступно всем". Также во все поля "URL" вводим
      ссылку:<br>
      &lt;script src=&#34;https://bookingeasy.ru/api/vk?group=0&key={{global.account.key}}&#34;&gt;&lt;/script&gt;<br>
      <img src="https://bookingeasy.ru/images/doc/vk2.jpg" class="img-doc"><br><br>
       </p>

    </div>
</template>

<style>

</style>

<script>
import {global} from "@/global.js";

export default {
    name: 'DocVkView',
        data() {
            return {
            global: global
        }
    }
}
</script>
