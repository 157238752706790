<template>
  <LoginView/>
  <!-- start balance -->
  <section id="balance" v-if="global.authenticated">
    <div class="overlay">
      <div class="container">
        <div class="d-flex align-items-start flex-column justify-content-center">
          <h1 v-bind:class="{'text-danger': account.balance<0 }">Ваш баланс: {{account.balance}}</h1>
          <input v-if="!addBalanceVisible" class="btn btn-primary main-btn text-uppercase" @click="addBalanceVisible=true" value="Пополнить"><br><br>
          <table v-if="!addBalanceVisible">
            <tr>
              <td class="cell">Платёж</td>
              <td class="cell">Сумма</td>
              <td class="cell">Дата</td>
            </tr>
            <tr v-for="(p,index) in pay" :key="index">
              <td class="cell">{{p.description}}</td>
              <td class="cell">{{p.pay}}</td>
              <td class="cell">{{getDate(p.createdDt)}}</td>
            </tr>
          </table>

          <div v-if="addBalanceVisible" class="modaltttt" tabindex="32000" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="addBalanceVisible=false" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h5 class="modal-title">Пополнить баланс</h5>
                </div>
                <div class="modal-body">
                  <p><input type="number" v-bind:min="addBalanceMinSum" v-model="addBalanceSum" ></p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary main-btn" @click="addBalanceSum=990">На месяц</button>
                  <button type="button" class="btn btn-secondary main-btn " @click="addBalanceSum=9900">На год</button><br><br>
                  <button type="button" class="btn btn-primary main-btn" @click="payForService()" >Оплатить</button>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
  <!-- end balance -->

</template>

<style>
#balance .overlay{
  background: none;
}
#balance table{
  background: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
}
#balance .cell{
  height: 52px;
  padding: 1px 16px;
  text-align: right;
}
#balance .modal-dialog{
  width: 400px;
}
#balance .modal-dialog .btn-primary{
  width: 100%;
}
#balance .modal-dialog input{
  width: 100%;
}
</style>

<script>
import axios from 'axios'
import Toast from '@/js/toast.min.js'
import LoginView from '@/views/LoginView.vue'
import {global} from "@/global.js";

export default {
  name: 'LkBalanceView',
  components: {
    LoginView
  },
  data() {
    return {
      global: global,
      account:{subjects:[]},
      pay:[],
      addBalanceVisible:false,
      addBalanceMinSum:100,
      addBalanceSum:100
    }
  },

  mounted() {
    global.value.lk=true;
    if(global.value.authenticated) {
      this.load();
    }else {
      setTimeout(function () {
        if (global.value.authenticated) {
          this.load();
        }
      }.bind(this), 1000);
    }
  },


  methods: {

    getAccount(){
      axios.get(global.value.url + "lk/account").then(response => {
        this.account = response.data;
      });
    },
    load(){
      this.getAccount();
      this.getPay();
    },
    getPay(){
      axios
          .get(global.value.url + "lk/pay")
          .then(response => {
            this.pay=response.data;
          });
    },
    getDate(d){
      var date = new Date(d);
      return ('0' + (date.getDate())).slice(-2)+'.'+('0' + (date.getMonth() + 1)).slice(-2)+'.'+date.getFullYear()+' '+('0' + date.getHours()).slice(-2)+':'+('0' + date.getMinutes()).slice(-2)+':'+('0' + date.getSeconds()).slice(-2);
    },
    payForService(){
      this.loading = true;
      this.accountErorr = "";
      axios
          .post(global.value.url + "pay-for-service?amount="+(this.addBalanceSum * 100))
          .then(response => {
            //if(!response.data.success)this.accountErorr=response.data.text;
            if(response.data.success){
              window.location.href = response.data.text;
            }else{
              new Toast({
                title: false,
                text: response.data.text,
                theme: 'danger',autohide: true,interval: 10000
              });
            }
            this.errored = false;
          })
          .catch(() => {
            this.accountErorr="Ошибка сохранения";
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  }
}
</script>
