<template>
    <div>
      <h2>Инструкция по добавлению виджета в  конструктор сайтов Tilda</h2>
      <p>1. Нажмите на кнопку добавления блока</p>
      <p>2. Выберете другое, потом Html-код (доступен только на платной версии Tilda)</p>
      <p>3. Вставьте код из своего личного кабинета (из раздела "Помощь"), для demo аккаунта этот код такой:<br>
      &lt;booking-widget&gt;&lt;/booking-widget&gt;<br>
      &lt;script src=&#34;https://bookingeasy.ru/api/widget?group=0&key={{global.account.key}}&#34;&gt;&lt;/script&gt;</p>
      <p>4. Нажмите опубликовать и проверьте результат(через настройки блока можно выставить по центру)</p><br>
      <img src="https://bookingeasy.ru/images/doc/tilda0.jpg" class="img-doc"><br><br>
      <img src="https://bookingeasy.ru/images/doc/tilda1.jpg" class="img-doc"><br><br>
      <img src="https://bookingeasy.ru/images/doc/tilda2.jpg" class="img-doc"><br><br>
      <img src="https://bookingeasy.ru/images/doc/tilda3.jpg" class="img-doc"><br><br>
    </div>
</template>

<style>
</style>

<script>
import {global} from "@/global.js";

export default {
  name: 'DocTildaView',
  data() {
    return {
      global: global
    }
  }
}
</script>
